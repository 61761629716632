/* Kivo customisations */ :root {
	--font-heading-family: "BR Firma", "SF Pro Text", "Helvetica Neue", Helvetica, sans-serif !important;
	--font-body-family: "BR Firma", "SF Pro Text", "Helvetica Neue", Helvetica, sans-serif !important;
	--bs-body-font-size: 1.5em;
	--bs-heading-color: rgba(0,0,0,1);
}
.inline-richtext a, .rte.inline-richtext a, .link, .rte a, a {
	font-weight: bold;
	text-underline-offset: 0.15em;
}
main a, main .rte a, main .link, footer a, footer .rte a, footer .link {
	color: rgb(13, 110, 253);
}
.button {
	background-color: #F20303;
	color: white !important;
	font-weight: bolder;
}
.button:hover, .button-primary:hover {
	color: white !important;
	background-color: #BF0000 !important;
}
.button--secondary, .fera-btn {
	background-color: #F20303;
	border: 0px solid #F20303;
	color: white;
	font-weight: bold !important
}
.footer_link_small a, .footer_link_small {
	font-size: 1.3rem;
	opacity: 1;
	margin-bottom: 0.2rem
}
.sharethis-inline-share-buttons {
	margin: 2em 0
}
h1, h2, h3, h4, h5 {
	color: rgba(0,0,0,1);
}
.rte a:hover:not(button), a:hover:not(button) {
	color: red;
	text-underline-offset: 0.15em;
}
.rte img,
.image-with-text .image-with-text__media-item>* {
	box-shadow: none !important
}
h1 {
	text-align: center;
}
.header .header__inline-menu {
	margin-top: 0em;
}
.product-form__buttons {
	margin: auto;
	max-width: 100% !important
}
.kivo-media {
	display: flex;
	align-items: flex-start;
	background: transparent !important;
	padding: 1em;
}
.kivo-media img {
	margin-right: 1em;
	height: auto !important;
	position: relative !important;
	width: 25% !important;
	max-width: 10em !important
}
.kivo-media-body {
	position: relative !important;
	flex: 1;
}
.kivo-media-heading {
	margin: 0 0 .5em;
}
/* for form based responses */
.form__message {
	align-items: flex-start;
	background-color: rgba(255, 0, 0, 0.34);
	padding: 1em;
	border-radius: 1em;
	font-size: 1.5em;
}
.form__message .icon, .form__message svg {
	flex-shrink: 0;
	height: 4rem;
	margin-right: 1rem;
	width: 4rem;
}
a, .full-unstyled-link {
	text-decoration: underline
}
/* hero image box backgrounds */
.banner__box.gradient {
	background-color: rgba(255, 255, 255, 0.65) !important;
	background: none;
}
.text-center {
	text-align: center
}
.fs-small {
	font-size: 0.75em
}
.announcement-bar__message {
	font-size: 1.2rem!important;
	font-weight: 700!important;
	margin: .8em 0 0 0 !important;
}
.announcement-bar__message * {
	color: white !important
}
.pagination__list {
	width: 100%;
	margin-bottom: 5em;
}
.pagination__item {
	font-size: 2rem !important;
	background-color: rgba(255,255,255,0.2);
	border-radius: 0.5rem;
}
.pagination__item:hover {
	background-color: rgba(255,255,255,0.3);
}
@font-face {
	font-family: 'BR Firma';
	src: url('https://mykivo.com/cdn/shop/files/BRFirma-Bold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'BR Firma';
	src: url('https://mykivo.com/cdn/shop/files/BRFirma-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'BR Firma';
	src: url('https://mykivo.com/cdn/shop/files/BRFirma-Medium.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
body, h1, h2, h3, h4, h5, .fera-review *,
.field__input, .select__select, .customer .field input, .customer select {
	font-family: var(--font-body-family);
}
* {
	letter-spacing: -0.005em;
}
h1, h2, h3, h4, h5 {
	color: rgba(0,0,0,1);
}
h1 {
	font-weight: 600 !important;
}
h1, h2 {
	letter-spacing: -0.04em !important;
}
h3, h4, h5 {
	letter-spacing: -0.015em !important;
}
h2, h3, h4, h5 {
	margin-top: 1em;
	font-weight: 600 !important;
}
.subtitle {
	font-size: 2vw
}

.card .article--card,
.card.gradient,
.card .gradient {
	background: none;
	background-color: rgba(255,255,255,0.1);
}
.rte-width {
	margin: 0 auto;
}
.header-wrapper {
	background:  rgba(255, 255, 255, 0.85);
	background-color: rgba(255, 255, 255, 0.85);
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
}
.header:not(.header--middle-left,.header--middle-center) .header__inline-menu {
	margin-top: 0
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer {
	color: white
}
.image-with-text:not(.image-with-text--overlap) .image-with-text__media-item:after,
.image-with-text:after,
.global-media-settings:after {
    content: "" !important;
    box-shadow: unset !important;
}
.badge {
	background-color: rgb(74, 44, 195);
	text-transform: uppercase;
	color: white;
}
@media screen and (min-width: 990px) {
	.rich-text__blocks {
		max-width: 100% !important;
	}
}
.header__heading-link {
	padding-bottom: 0
}
table,
td {
	width: auto;
	border: solid 0px transparent !important;
	align-content: flex-start;
}
table {
	width: 100%;
	margin: 2em 0
}
@media screen and (max-width: 989px) {
	.subtitle {
		font-size: 4vw
	}
	.kivo-media {
		display: block;
	}
	.kivo-media-object {
		max-width: 50% !important;
		width:50% !important;
	}
	.kivo-media img {
		max-width: 100% !important;
		width:50% !important;
	}
	.header__heading-link {
		padding-bottom: 0.5rem
	}
	
}